<template>
  <div>
    <b-modal
      id="addModals"
      ref="addCommentModal"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="lg"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          Add Comment Form
        </h3>
      </div>
      <validation-observer
        v-if="!isLoading"
        ref="userComments"
        tag="form"
        @submit.prevent="addComment"
      >
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Comment"
              rules="required"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="commentary.description"
                :options="editorOption"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Attachment">
            <validation-provider
              #default="{ errors }"
              name="Attachment"
              rules=""
            >
              <vue-dropzone
                id="fileInputP"
                ref="myFileP"
                name="fileInputP"
                style="min-height: 1rem;"
                :options="dropzoneOptions"
                @vdropzone-sending="sendingFileRequestEvent"
                @vdropzone-complete="completeFileRequestEvent"
                @vdropzone-success="uploadFileRequestSuccess"
                @vdropzone-error="errorFileRequestEvent"
                @vdropzone-removed-file="removeFileRequest"
              />
              <small class="text-danger">{{ (errAttachment.fileInputP != '') ? errAttachment.fileInputP : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <loading v-if="isLoading" />
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="closeFormRequest"
        ><i class="fa fa-undo" /> Back</b-button>
        &nbsp;
        <b-button
          type="button"
          size="sm"
          variant="primary"
          :disabled="isLoading || submitDisabled"
          @click="addComment"
        ><i class="fa fa-save" /> Save</b-button>
      </div>
    </b-modal>

    <b-card>
      <b-card-title>
        <div class="d-flex justify-content-between">
          <span>Comments</span>
          <b-button
            variant="primary"
            @click="showModalForm"
          >
            Add Comment
          </b-button>
        </div>
      </b-card-title>
      <div
        v-if="comments.length > 0"
        class="overflow-auto py-1"
        style="max-height: 65vh;"
      >
        <app-timeline
          v-for="comment in comments"
          :key="comment.id"
        >
          <app-timeline-item
            variant="primary"
            :user="`${comment.inserted_by} - ${comment.user_name}`"
            :description="comment.description"
            :time="comment.inserted_time"
            :icon="null"
            :attachment="comment.attachment"
          />
        </app-timeline>
      </div>
      <div v-else>
        <p class="text-center">
          No Comments
        </p>
      </div>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import axios from 'axios'
import interfaces from '@/templates/_interfaces'
import AppTimeline from '@/@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@/templates/AppTimelineItem.vue'
import moment from 'moment'
import _accessApi from '@/router/_accessApi'
import Loading from '@core/loading/Loading.vue'

export default {
  name: 'CommentCard',
  components: {
    ValidationProvider,
    ValidationObserver,
    vueDropzone: vue2Dropzone,
    quillEditor,
    AppTimeline,
    AppTimelineItem,
    Loading,
  },
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      submitDisabled: false,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
          ],
        },
      },
      previewImage: null,
      commentary: interfaces.commentary,
      errAttachment: {
        fileInputP: null,
      },
      dropzoneOptions: {
        url: `${axios.defaults.baseURL}upload/add-assets`,
        thumbnailWidth: 150,
        maxFilesize: 20,
        paramName: 'attachment',
        disablePreviews: true,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: 'image/*,application/pdf,.xls,.xlsx,.csv',
        headers: {
          'Cache-Control': '',
        },
      },
    }
  },
  methods: {
    addComment() {
      this.$refs.userComments.validate().then(success => {
        if (success) {
          this.submitDisabled = true
          this.commentary.request_code = this.code
          this.$swal({
            title: 'Add Comment',
            text: 'Are you sure want to Add this comment ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1',
            },
          }).then(result => {
            if (result.value) {
              this.isLoading = true
              this.isLoading = true
              axios.post('request/comment', this.commentary).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Add Comment Successful',
                    icon: 'EditIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.$emit('submitted')
                this.closeFormRequest()
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Add Comment Failed',
                    icon: 'EditIcon',
                    variant: 'danger',
                    text: error.message,
                  },
                })
              }).finally(() => {
                this.isLoading = false
              })
            }
          })
        }
      })
    },
    uploadFileRequestSuccess(file, response) {
      this.submitDisabled = false
      this.errAttachment.file = null
      this.commentary.attachment = response.path
    },
    sendingFileRequestEvent(file, xhr, formData) {
      this.submitDisabled = true
      formData.append('folder_name', 'comment')
    },
    errorFileRequestEvent(file, error, xhr) {
      this.$swal({
        title: 'Upload Failed',
        text: error,
        icon: 'error',
        confirmButtonText: 'OK',
      })
      this.commentary.attachment = null
      this.$refs.myFileP.removeAllFiles()
    },
    completeFileRequestEvent(param) {
      if (param.status === 'error') {
        this.$refs.myFileP.removeAllFiles()
      }
    },
    removeFileRequest(file, error, xhr) {
      // if submitting, dont remove file
      if (this.submitDisabled) return
      // remove file
      if (this.commentary.attachment != null) {
        axios.post('upload/remove-image', { path: this.commentary.attachment }).then(response => {
          this.commentary.attachment = null
        }).finally(() => {
          this.isLoading = false
        })
      }
      this.submitDisabled = false
    },
    resetForm() {
      Object.keys(interfaces.commentary).forEach(key => {
        interfaces.commentary[key] = null
      })
    },
    closeFormRequest() {
      this.resetForm()
      this.$refs.addCommentModal.hide()
    },
    showModalForm() {
      this.$refs.addCommentModal.show()
    },
    moment(date) {
      return moment(date)
    },
    attachment(path) {
      // open new tab
      if (path === null) return
      window.open(`${_accessApi.localhost.upload_path_preview}${path}`, '_blank')
    },

  },
}
</script>
