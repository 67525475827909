<template>
  <div>
    <!-- MODAL PREVIEW -->
    <b-modal
      id="previewModals"
      ref="previewModal"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      size="xl"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          Preview Attachment
        </h3>
      </div>
      <div class="d-flex justify-content-center">
        <img
          v-if="previewType == 'image'"
          :src="previewUrl"
          class="img-fluid"
        >
        <object
          v-if="previewType == 'pdf'"
          :data="previewUrl"
          type="application/pdf"
          width="100%"
          height="600px"
        >
          <p>Your web browser doesn't have a PDF plugin. <a :href="previewUrl">click here to download the PDF file.</a>
          </p>
        </object>
      </div>

      <loading v-if="isLoading" />
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="$bvModal.hide('previewModals')"
        ><i class="fa fa-undo" /> Back</b-button>
        &nbsp;
      </div>
    </b-modal>
    <b-card>
      <b-card-title>
        Details
      </b-card-title>
      <b-form v-if="!isLoading">
        <b-form-group
          label="Application"
          label-for="input-application"
        >
          <model-select
            v-model="request.application_id"
            :options="listapplication"
            placeholder="-- Please select --"
            :is-disabled="isDisabled"
            @input="getApplicationPurpose"
          />
        </b-form-group>
        <b-form-group
          v-if="showPurpose"
          label="Application Purpose"
          label-for="input-application"
        >
          <model-select
            v-model="request.application_purpose"
            :options="listapplicationPurpose"
            placeholder="-- Please select --"
            :is-disabled="isDisabled"
          />
          <loading v-if="isLoading" />
        </b-form-group>
        <b-form-group
          label="Title"
          label-for="input-title"
        >
          <b-form-input
            id="input-title"
            v-model="request.title"
            placeholder="Title"
            :disabled="isDisabled"
          />
        </b-form-group>
        <b-form-group
          label="Requester"
          label-for="input-requester"
        >
          <model-select
            v-model="request.requested_by"
            :options="employeeList"
            placeholder="-- Please select --"
            :is-disabled="isDisabled"
          />
        </b-form-group>
        <b-form-group
          label="Request Date"
          label-for="input-date"
        >
          <flat-pickr
            v-model="request.requested_time"
            :config="flatpickTimeConfig"
            placeholder="Request Date"
            :disabled="isDisabled"
          />
        </b-form-group>
        <b-form-group
          label="Due Date"
          label-for="input-duedate"
        >
          <flat-pickr
            v-model="request.due_date"
            :config="flatpickconfig"
            placeholder="Due Date"
            :disabled="isDisabled"
          />
        </b-form-group>

        <b-form-group
          label="Priority"
          label-for="input-priority"
        >
          <model-select
            v-model="request.priority_status"
            :options="priorities"
            placeholder="-- Please select --"
            :is-disabled="isDisabled"
          />
        </b-form-group>
        <b-form-group
          label="Description"
          label-for="input-description"
        >
          <quill-editor
            ref="myQuillEditorDetail"
            v-model="request.description"
            :options="editorOption"
            :disabled="isDisabled"
          />
          <!-- <b-form-textarea
            id="input-description"
            v-model="request.description"
            placeholder="Description"
            rows="3"
            readonly
          /> -->
        </b-form-group>
        <b-form-group
          label="Assign To"
          label-for="input-assignto"
        >
          <multiselect
            v-model="selected_dev"
            :options="listDeveloper"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pick some"
            label="text"
            track-by="value"
            :disabled="isDisabledAssign"
          />
        </b-form-group>
        <b-form-group
          label="CC Email"
          label-for="input-ccto"
        >
          <multiselect
            v-model="selectedCC"
            :options="employeeEmailList"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pick some"
            label="text"
            track-by="value"
            :disabled="isDisabledAssign"
          />
        </b-form-group>
        <b-form-group
          label="Status"
          label-for="input-status"
        >
          <model-select
            v-model="request.status"
            :options="statuses"
            placeholder="-- Please select --"
            :is-disabled="isDisableDev"
          />
        </b-form-group>
        <div
          v-if="isAdmin == 1 && isViewOnly == 0"
          class="float-right"
        >
          <b-button
            type="button"
            variant="primary"
            @click="submitAdd"
          ><i class="fa fa-save" /> Update</b-button>
        </div>

        <a
          v-if="request.attachment"
          class="text-primary"
          @click="getAttachment(request.attachment)"
        >
          <feather-icon
            :icon="'PaperclipIcon'"
            size="18"
          />
        </a>
      </b-form>
      <loading v-if="isLoading" />
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Multiselect from 'vue-multiselect'
import _accessApi from '@/router/_accessApi'
import Loading from '@core/loading/Loading.vue'

const SUPER_ADMIN_LIST = ['NCT1509059', 'NCT2406030']
const ASSIGNER_LIST = ['NCT1503041', 'NCT1811228']
export default {
  name: 'RequestDetailCard',
  components: {
    flatPickr,
    quillEditor,
    Multiselect,
    Loading,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    priorities: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      code: this.$route.params.code,
      isLoading: false,
      isDisabled: true,
      employeeList: [],
      employeeEmailList: [],
      selectedCC: [],
      isDisableDev: false,
      isDisabledAssign: true,
      isAdmin: localStorage.getItem('admin'),
      isViewOnly: localStorage.getItem('view_only'),
      nik: localStorage.getItem('nik'),
      flatpickconfig: {
        altFormat: 'd-M-Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: false,
        // minDate: 'today',
      },
      flatpickTimeConfig: {
        altFormat: 'd-M-Y H:i:s',
        altInput: true,
        dateFormat: 'Y-m-d H:i:s',
        enableTime: true,
        // minDate: 'today',
      },
      listapplication: [],
      listapplicationPurpose: [],
      showPurpose: false,
      listDeveloper: [],
      selected_dev: [],
      previewType: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
          ],
        },
      },
    }
  },
  created() {
    this.get_dropdown()
    this.get_assign_to()
    this.getEmployeeDropdown()
    this.getEmployeeEmailDropdown()
    if (this.request.application_purpose) {
      this.getApplicationPurpose(this.request.application_id)
    }
    const isAdmin = Number(this.isAdmin) === 1
    const isSuperAdmin = SUPER_ADMIN_LIST.includes(this.nik)
    const isAssigner = ASSIGNER_LIST.includes(this.nik)
    if (isAdmin) {
      this.isDisableDev = false
      this.isDisabledAssign = false
      if (isSuperAdmin) {
        this.isDisabled = false
      } else if (isAssigner) {
        this.isDisabled = true
      } else {
        this.isDisabled = true
      }
    } else {
      this.isDisabled = true
      this.isDisableDev = true
      this.isDisabledAssign = true
    }
    this.selectedCC = this.request.cc_email_list
  },
  methods: {
    get_dropdown() {
      axios.get('dropdown/list').then(response => {
        this.listapplication = response.data.data
        this.listDeveloper = response.data.developer_list
      }).catch(error => {

      }).finally(() => {

      })
    },
    getApplicationPurpose(applicationId) {
      // this.request.application_purpose = null
      axios.get(`dropdown/application-purpose/${applicationId}`).then(response => {
        if (response.data.data.length > 0) {
          this.listapplicationPurpose = response.data.data
          this.showPurpose = true
        } else {
          this.showPurpose = false
        }
      }).catch(error => {

      }).finally(() => {
      })
    },
    get_assign_to() {
      axios.get(`request/assign-list/${this.code}/${this.nik}`).then(response => {
        this.selected_dev = response.data.data
        if (!response.data.cek && !SUPER_ADMIN_LIST.includes(this.nik)) {
          this.isViewOnly = 1
          this.isDisableDev = true
        }
      })
    },
    getEmployeeDropdown() {
      this.isLoading = true
      axios.get('dropdown/nik').then(response => {
        this.employeeList = response.data.data
      }).catch(error => {
        // console.log(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    getEmployeeEmailDropdown() {
      this.isLoading = true
      axios.get('dropdown/employee-email').then(response => {
        this.employeeEmailList = response.data.data
      }).catch(error => {
        // console.log(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    submitAdd() {
      this.request.selected_dev = this.selected_dev
      this.request.nik = this.nik
      this.$swal({
        title: 'Update Request',
        text: 'Are you sure want to Update this request ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          if (SUPER_ADMIN_LIST.includes(this.nik) || ASSIGNER_LIST.includes(this.nik) || Number(this.isAdmin) === 1) {
            this.request.cc_email_list = this.selectedCC.map(item => item.value)
          }
          this.isLoading = true
          axios.post('request/assign_to', this.request).then(response => {
            this.$emit('submitted')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Request Successful',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Request Failed',
                icon: 'EditIcon',
                variant: 'danger',
                text: error.message,
              },
            })
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
    getAttachment(path) {
      if (path === null) return
      // if path is image

      this.previewUrl = `${_accessApi.localhost.upload_path_preview}${path}`
      if (path.toLowerCase().match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/) != null) {
        this.previewType = 'image'
        this.$refs.previewModal.show()
      } else if (path.toLowerCase().match(/\.(pdf)$/) != null) {
        this.previewType = 'pdf'
        this.$refs.previewModal.show()
      } else {
        window.open(this.previewUrl, '_blank')
      }
    },
  },
}
</script>
