<template>
  <div>
    <loading v-if="isLoading" />
    <div
      v-if="!isLoading"
      class="row"
    >
      <b-col
        md="4"
        sm="12"
      >
        <request-detail-card
          :request="request"
          :priorities="priorities"
          :statuses="statuses"
          @submitted="handleRequest"
        />
      </b-col>
      <b-col
        md="8"
        sm="12"
      >
        <comment-card
          :comments="comments"
          :code="code"
          @submitted="handleRequest"
        />
      </b-col>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import interfaces from '@/templates/_interfaces'
import CommentCard from '@/views/component/CommentCard.vue'
import RequestDetailCard from '@/views/component/RequestDetailCard.vue'
import Loading from '@core/loading/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Request',
  components: {
    CommentCard,
    RequestDetailCard,
    Loading,
  },
  data() {
    return {
      // id get from params
      code: this.$route.params.code,
      commentary: interfaces.commentary,
      details: null,
      isLoading: false,
      comments: [],
      request: interfaces.request_detail,
      priorities: [],
      statuses: [],
      listEmployee: [],
    }
  },
  mounted() {
    this.getRequest()
  },
  methods: {
    async getRequest() {
      this.isLoading = true
      // get flag from params
      await axios
        .get(`/request/detail/${this.code}`)
        .then(response => {
          this.comments = response.data.comments
          this.request = response.data.data
          this.priorities = response.data.request_priority.map(item => ({
            value: item.id,
            text: item.name,
            disabled: true,
          }))
          this.statuses = response.data.request_status.map(item => ({
            value: item.id,
            text: item.name,
            disabled: true,
          }))
          this.stages = response.data.request_status.map(item => item.name)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
          if (error.response.data.status === 4000) {
            this.$router.push({ name: 'dashboard' })
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    handleRequest() {
      this.getRequest()
    },
    handleCardClick(card) {
      this.details = card
      this.$refs.modalForm.show()
    },
    closeDetailCard() {
      this.details = null
      this.$refs.modalForm.hide()
    },
  },
}
</script>
